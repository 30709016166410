import { GetCategoryTypePatterns, GetStylePatterns, GetStyleVariationPattern } from '../style-patterns/index.js';
export const CategoryTypes = ['Championship', 'Scholarship', 'Improvisation', 'Dancers with Disabilities', 'Standard'];
export const BaseCompStyles = ['Any Style', 'Acrobatics', 'Broadway', 'Classical Ballet', 'Contemporary', 'Demi-character', 'Hip Hop', 'Improvisation', 'Jazz', 'Lyrical', 'Musical Theatre', 'National Character', 'Neo Classical', 'Song & Dance', 'Student Choreography', 'Tap', 'Variety', 'Other'];
export const ComputeDisplayStyle = (compStyle, styleVariations = []) => {
  const variations = styleVariations.length ? ` (${styleVariations.join(', ')})` : '';
  const displayStyle = Array.isArray(compStyle) ? compStyle.sort().join(', ') : '';
  return `${displayStyle}${variations}`;
};
export const StandardiseCompStyle = style => {
  const stylePatterns = GetStylePatterns();
  const categoryTypePatterns = GetCategoryTypePatterns();
  const styleVariationPatterns = GetStyleVariationPattern();
  // console.log(
  //   `StandardiseCompStyle - stylePatterns=${stylePatterns.length}, categoryTypePatterns=${categoryTypePatterns.length}, styleVariationPatterns=${styleVariationPatterns.length}`
  // );
  /**
   * initialise result
   */
  const standardisedResult = {
    compStyles: [],
    categoryType: 'Standard',
    styleVariations: [],
    basicStyles: ['Other']
  };
  if (!style) return joinCompStyles(standardisedResult);
  // scrub entered style - lowercase, remove leading/trailing whitespace, remove non-word characters
  const removeLeadingTrailingCrapPattern = /^[\d\s\W]*(.*?)[\d\s\W]*$/;
  const styleLC = String(style).toLowerCase();
  const match = styleLC.toLowerCase().match(removeLeadingTrailingCrapPattern);
  // Regex to match and exclude parentheses starting with "exc"
  const excludeRegex = /\((exc\w*[^)]*)\)/g;
  // Remove the excluded parts from the text
  // const cleanedText = text.replace(excludeRegex, '');
  // If there's a match, return the captured group, otherwise return the original string
  const style1 = match ? match[1] : styleLC;
  const noCrapPattern = /\W+/g;
  const scrubbedStyle = style1.replace(noCrapPattern, '').replace(excludeRegex, '');
  /**
   * Determine category types
   */
  const matchingCategoryTypePattern = GetFirstMatchingStylePattern(categoryTypePatterns, scrubbedStyle);
  if (matchingCategoryTypePattern?.categoryType) standardisedResult.categoryType = matchingCategoryTypePattern.categoryType;
  /**
   * Determine if variation
   */
  const matchingStyleVariationPatterns = GetAllMatchingStylePatterns(styleVariationPatterns, scrubbedStyle);
  if (matchingStyleVariationPatterns?.length) standardisedResult.styleVariations = matchingStyleVariationPatterns.map(x => x.compStyleVariation);
  // const styleVariations = testStyleVariationPatterns(styleVariationPatterns, scrubbedStyle);
  // if (styleVariations) standardisedResult.styleVariations = styleVariations;
  /**
   * Get all matching styles
   */
  const matchingStylePatterns = GetAllMatchingStylePatterns(stylePatterns, scrubbedStyle);
  if (matchingStylePatterns?.length) {
    const basicStylesSet = new Set();
    for (const sp of matchingStylePatterns) {
      for (const basicStyle of sp.compStyles) {
        basicStylesSet.add(basicStyle);
      }
    }
    standardisedResult.basicStyles = Array.from(basicStylesSet);
  }
  /**
   * Default if no matches found
   */
  if (!standardisedResult.basicStyles.length) {
    if (standardisedResult.categoryType !== 'Standard') {
      standardisedResult.basicStyles.push('Any Style');
    } else {
      standardisedResult.basicStyles.push('Other');
    }
  }
  // return our results
  return joinCompStyles(standardisedResult);
}; // end StandardiseCompStyle
export const ComputeFullStyle = (displayStyle, compSoloOrGroup, compRangeDesc) => {
  const fullStyle = `${displayStyle}, ${compSoloOrGroup}${compRangeDesc ? `, ${compRangeDesc}` : ''}`;
  return fullStyle;
};
/**
 * Computes a joined compStyle from the base style
 */
const joinCompStyles = inStyles => {
  if (!inStyles.categoryType || inStyles.categoryType === 'Standard') {
    inStyles.compStyles = inStyles.basicStyles;
    return inStyles;
  }
  const joinedStyles = [];
  for (const basicStyle of inStyles.basicStyles) {
    const joinedStyle = `${basicStyle} ${inStyles.categoryType}`;
    joinedStyles.push(joinedStyle);
  }
  inStyles.compStyles = joinedStyles;
  return inStyles;
};
export const TestStylePattern = (stylePattern, scrubbedStyle, patternKey) => {
  // equals comparison first
  if (Array.isArray(stylePattern[patternKey]) && stylePattern[patternKey].length > 0) {
    for (const patternString of stylePattern[patternKey].filter(x => !!x)) {
      const regex = new RegExp(`${patternString}`, 'i');
      if (regex.test(scrubbedStyle)) {
        return true;
      }
    }
  }
  return false;
};
export const GetFirstMatchingStylePatternForKey = (stylePatterns, scrubbedStyle, patternKey) => {
  for (const stylePattern of stylePatterns) {
    if (TestStylePattern(stylePattern, scrubbedStyle, patternKey)) {
      return stylePattern;
    }
  }
  return undefined;
};
export const GetAllMatchingStylePatternsForKey = (stylePatterns, scrubbedStyle, patternKey) => {
  const matchingPatterns = [];
  for (const stylePattern of stylePatterns) {
    if (TestStylePattern(stylePattern, scrubbedStyle, patternKey)) {
      matchingPatterns.push(stylePattern);
    }
  }
  return matchingPatterns;
};
export const GetAllMatchingStylePatterns = (stylePatterns, scrubbedStyle) => {
  for (const patternKey of ['primaryPatterns', 'secondaryPatterns', 'tertiaryPatterns']) {
    const result = GetAllMatchingStylePatternsForKey(stylePatterns, scrubbedStyle, patternKey);
    if (result.length > 0) {
      return result;
    }
  }
  return undefined;
};
export const GetFirstMatchingStylePattern = (stylePatterns, scrubbedStyle) => {
  for (const patternKey of ['primaryPatterns', 'secondaryPatterns', 'tertiaryPatterns']) {
    const result = GetFirstMatchingStylePatternForKey(stylePatterns, scrubbedStyle, patternKey);
    if (result) {
      return result;
    }
  }
  return undefined;
};
