let AllStylePatterns = [];
let StylePatterns = [];
let CategoryTypePatterns = [];
let StyleVariationPattern = [];
let SoloOrGroupPatterns = [];
export const SetStylePatterns = stylePatterns => {
  AllStylePatterns = [...stylePatterns];
  console.log(`👀 setStylePatterns - AllStylePatterns=${AllStylePatterns.length}`);
  StylePatterns = AllStylePatterns.filter(x => x.patternType === 'stylePattern');
  CategoryTypePatterns = AllStylePatterns.filter(x => x.patternType === 'categoryTypePattern');
  StyleVariationPattern = AllStylePatterns.filter(x => x.patternType === 'styleVariationPattern');
  SoloOrGroupPatterns = AllStylePatterns.filter(x => x.patternType === 'soloOrGroupPattern');
};
export const GetAllStylePatterns = () => {
  return AllStylePatterns;
};
export const GetStylePatterns = () => {
  return StylePatterns;
};
export const GetCategoryTypePatterns = () => {
  return CategoryTypePatterns;
};
export const GetStyleVariationPattern = () => {
  return StyleVariationPattern;
};
export const GetSoloOrGroupPatterns = () => {
  return SoloOrGroupPatterns;
};
